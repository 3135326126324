import { NutakuBlackFriday2024OfferComponent } from './nutaku-black-friday-2024/nutaku-black-friday-2024.component';
import { NutakuCyberMonday2024OfferComponent } from './nutaku-cyber-monday-2024/nutaku-cyber-monday-2024.component';
import { BigBreastsFlatChestsComponent } from './big-breasts-flat-chests/big-breasts-flat-chests.component';
import { NutakuBirthday2024Component } from './nutaku-birthday-2024/nutaku-birthday-2024.component';
import { ValentineDay2024Component } from './valentine-day-2024/valentine-day-2024.component';
import { SweaterWeatherComponent } from './sweater-weather/sweater-weather.component';
import { Christmas2024OfferComponent } from './christmas2024/christmas2024.component';
import { OfferItemsListModule } from '../offer-items-list/offer-items-list.module';
import { GoldenWeekOfferComponent } from './golden-week/golden-week.component';
import { MonsterGirlComponent } from './monster-girl/monster-girl.component';
import { StarterPackComponent } from './starter-pack/starter-pack.component';
import { Easter2024Component } from './easter-2024/easter-2024.component';
import { Winter2024Component } from './winter2024/winter2024.component';
import { PriceBlockModule } from '../price-block/price-block.module';
import { OnlyfansComponent } from './onlyfans/onlyfans.component';
import { BigPackComponent } from './big-pack/big-pack.component';
import { ButtonModule } from '../controls/button/button.module';
import { DiscordComponent } from './discord/discord.component';
import { PipesModule } from '../../pipes/pipes.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [
    NutakuBlackFriday2024OfferComponent,
    NutakuCyberMonday2024OfferComponent,
    BigBreastsFlatChestsComponent,
    NutakuBirthday2024Component,
    Christmas2024OfferComponent,
    ValentineDay2024Component,
    GoldenWeekOfferComponent,
    SweaterWeatherComponent,
    MonsterGirlComponent,
    StarterPackComponent,
    Winter2024Component,
    Easter2024Component,
    OnlyfansComponent,
    BigPackComponent,
    DiscordComponent,
  ],
  imports: [
    OfferItemsListModule,
    PriceBlockModule,
    CommonModule,
    ButtonModule,
    PipesModule,
  ],
  exports: [
    NutakuBlackFriday2024OfferComponent,
    NutakuCyberMonday2024OfferComponent,
    BigBreastsFlatChestsComponent,
    NutakuBirthday2024Component,
    Christmas2024OfferComponent,
    ValentineDay2024Component,
    GoldenWeekOfferComponent,
    SweaterWeatherComponent,
    MonsterGirlComponent,
    StarterPackComponent,
    Winter2024Component,
    Easter2024Component,
    OnlyfansComponent,
    BigPackComponent,
    DiscordComponent,
  ],
})
export class OfferTemplatesModule {}
